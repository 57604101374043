/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'recipes',
    title: 'Recetas',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/recipes',
  },
  {
    id: 'medications',
    title: 'Medicamentos',
    type: 'basic',
    icon: 'mat_outline:medical_services',
    link: '/medications',
  },
  {
    id: 'sustitution',
    title: 'Sustitución',
    type: 'basic',
    icon: 'heroicons_outline:arrows-up-down',
    link: '/sustitution',
  },
  {
    id: 'rejection',
    title: 'Rechazos',
    type: 'basic',
    icon: 'heroicons_outline:no-symbol',
    link: '/rejection',
  },
  {
    id: 'administration',
    title: 'Administración',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/administration',
    hidden: (item: FuseNavigationItem) =>
      Number(localStorage.getItem('role')) < 8,
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'recipes',
    title: 'Recetas',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/recipes',
  },
  {
    id: 'medications',
    title: 'Medicamentos',
    type: 'basic',
    icon: 'mat_outline:medical_services',
    link: '/medications',
  },
  {
    id: 'sustitution',
    title: 'Sustitución',
    type: 'basic',
    icon: 'heroicons_outline:arrows-up-down',
    link: '/sustitution',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'recipes',
    title: 'Recetas',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/recipes',
  },
  {
    id: 'medications',
    title: 'Medicamentos',
    type: 'basic',
    icon: 'mat_outline:medical_services',
    link: '/medications',
  },
  {
    id: 'sustitution',
    title: 'Sustitución',
    type: 'basic',
    icon: 'heroicons_outline:arrows-up-down',
    link: '/sustitution',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'recipes',
    title: 'Recetas',
    type: 'basic',
    icon: 'heroicons_outline:document-text',
    link: '/recipes',
  },
  {
    id: 'medications',
    title: 'Medicamentos',
    type: 'basic',
    icon: 'mat_outline:medical_services',
    link: '/medications',
  },
  {
    id: 'sustitution',
    title: 'Sustitución',
    type: 'basic',
    icon: 'heroicons_outline:arrows-up-down',
    link: '/sustitution',
  },
  {
    id: 'rejection',
    title: 'Rechazos',
    type: 'basic',
    icon: 'heroicons_outline:no-symbol',
    link: '/rejection',
  },
  {
    id: 'administration',
    title: 'Administración',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/administration',
    hidden: (item: FuseNavigationItem) =>
      Number(localStorage.getItem('role')) < 8,
  },
];
